import { ErrorMessage } from "@hookform/error-message"
import { Check, Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useAtom, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import type { BaseSyntheticEvent, FC } from "react"
import { Controller } from "react-hook-form"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { ApiError } from "src/shared/client"
import { buildProjectDetailPageUrl } from "src/shared/routing"
import { projectIdAtom, userAtom } from "src/shared/stores"
import { postNewProjectAtom } from "src/shared/stores"

type CreateProjectDialogProps = {
  isDialogOpen: boolean
  setIsDialogOpen: (newState: boolean) => void
  handleDrawerOpen: () => void
}

const CreateProjectDialog: FC<CreateProjectDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  handleDrawerOpen
}) => {
  const [{ data: user }] = useAtom(userAtom)
  const [{ isPending: isPostingNewProject, mutateAsync: postNewProject }] =
    useAtom(postNewProjectAtom)
  const setProjectId = useSetAtom(projectIdAtom)
  const defaultProjectData: ProjectPostRequest = {
    merchantId: user?.merchantId,
    status: "READY_FOR_APPROVAL",
    activityStatus: "ACTIVE",
    name: "",
    url: "",
    ageRating: undefined,
    category: undefined,
    isPrivacyPolicyAccepted: false
  }

  const useFormMethods = useForm<ProjectPostRequest>({
    mode: "all",
    values: defaultProjectData
  })
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails.createProjectDialog"
  })

  const editDialog = {
    projectName: {
      inputName: "name",
      labelName: t("name"),
      placeholder: t("namePlaceholder"),
      requiredRule: t("nameRequiredErrorMessage")
    },
    projectWebsite: {
      inputName: "url",
      labelName: t("url"),
      placeholder: t("urlPlaceholder"),
      requiredRule: t("urlRequiredErrorMessage")
    },
    projectAgeRating: {
      inputName: "ageRating",
      labelName: t("ageRating"),
      possibleAgeRating: [
        {
          text: t("defaultSelect", { keyPrefix: "common" }),
          value: ""
        },
        {
          text: t("possibleAgeRating.FSK0"),
          value: "FSK0"
        },
        {
          text: t("possibleAgeRating.FSK6"),
          value: "FSK6"
        },
        {
          text: t("possibleAgeRating.FSK12"),
          value: "FSK12"
        },
        {
          text: t("possibleAgeRating.FSK16"),
          value: "FSK16"
        },
        {
          text: t("possibleAgeRating.FSK18"),
          value: "FSK18"
        },
        {
          text: t("possibleAgeRating.FSK21"),
          value: "FSK21"
        }
      ]
    },
    projectCategory: {
      inputName: "category",
      labelName: t("category"),
      possibleCategories: [
        {
          text: t("defaultSelect", { keyPrefix: "common" }),
          value: ""
        },
        {
          text: t("possibleCategories.entertainment"),
          value: "entertainment"
        },
        {
          text: t("possibleCategories.infotainment"),
          value: "infotainment"
        },
        {
          text: t("possibleCategories.gaming"),
          value: "gaming"
        },
        {
          text: t("possibleCategories.community"),
          value: "community"
        },
        {
          text: t("possibleCategories.ecommerce"),
          value: "ecommerce"
        },
        {
          text: t("possibleCategories.webshop"),
          value: "webshop"
        },
        {
          text: t("possibleCategories.hosting"),
          value: "hosting"
        },
        {
          text: t("possibleCategories.onlineService"),
          value: "onlineService"
        },
        {
          text: t("possibleCategories.nonProfit"),
          value: "nonProfit"
        },
        {
          text: t("possibleCategories.other"),
          value: "other"
        }
      ]
    },
    projectPrivacyPolicyAccepted: {
      label: t("privacyPolicyCheckboxLabel"),
      requiredRule: t("privacyPolicyCheckboxRequiredErrorMessage")
    }
  }

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleNavigationToCreatedProjectDetails = (newProjectId: string) => {
    handleDrawerOpen()
    setProjectId(newProjectId)

    const projectDetailUrl = buildProjectDetailPageUrl(newProjectId)

    navigate(projectDetailUrl)
  }

  const handlePostProject = (e: BaseSyntheticEvent) =>
    useFormMethods.handleSubmit(
      async (newProjectData) => {
        try {
          const postedProject = await postNewProject({
            ...newProjectData,
            status: "READY_FOR_APPROVAL"
          })
          queryClient.invalidateQueries({
            queryKey: ["projectListData"],
            refetchType: "active"
          })
          useFormMethods.reset(defaultProjectData)
          enqueueSnackbar({
            variant: "success",
            message: t("postNewProjectSuccessMessage")
          })
          handleNavigationToCreatedProjectDetails(
            postedProject.projectId as string
          )
          setIsDialogOpen(!isDialogOpen)
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        console.error(errors)
      }
    )(e)

  return (
    <FormProvider {...useFormMethods}>
      <Dialog
        data-testid="createProjectDialog"
        open={isDialogOpen}
        disableScrollLock
        transitionDuration={{ enter: 225, exit: 0 }}
      >
        <DialogTitle id="alert-dialog-title">{t("title")}</DialogTitle>
        <DialogContent id="alert-dialog-content">
          <Grid container spacing={2}>
            <Grid item data-testid="projectNameInput" xs={6}>
              <FormInputGroup
                placeholder={editDialog.projectName.placeholder}
                labelName={editDialog.projectName.labelName}
                inputName={editDialog.projectName.inputName}
                isLabelRequired={true}
                rules={{
                  required: editDialog.projectName.requiredRule
                }}
              />
            </Grid>

            <Grid item data-testid="projectWebsiteInput" xs={6}>
              <FormInputGroup
                placeholder={editDialog.projectWebsite.placeholder}
                labelName={editDialog.projectWebsite.labelName}
                inputName={editDialog.projectWebsite.inputName}
                isLabelRequired={true}
                rules={{
                  required: editDialog.projectWebsite.requiredRule
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} data-testid="projectAgeRatingInput">
              <FormSelectGroup
                labelName={editDialog.projectAgeRating.labelName}
                inputName={editDialog.projectAgeRating.inputName}
                selectItems={editDialog.projectAgeRating.possibleAgeRating}
              />
            </Grid>

            <Grid item xs={12} md={6} data-testid="projectCategoryInput">
              <FormSelectGroup
                labelName={editDialog.projectCategory.labelName}
                inputName={editDialog.projectCategory.inputName}
                selectItems={editDialog.projectCategory.possibleCategories}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={useFormMethods.control}
                name="isPrivacyPolicyAccepted"
                rules={{
                  required: editDialog.projectPrivacyPolicyAccepted.requiredRule
                }}
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid="projectPrivacyPolicyCheckbox"
                          required
                          onChange={onChange}
                          checked={value}
                        />
                      }
                      label={editDialog.projectPrivacyPolicyAccepted.label}
                    />
                  </FormGroup>
                )}
              />
              <ErrorMessage
                errors={useFormMethods.formState.errors}
                name="isPrivacyPolicyAccepted"
                render={({ message }) => (
                  <Box
                    component="span"
                    data-testid="errorMessage"
                    role="alert"
                    className="McpErrorMessage"
                  >
                    {message}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className="McpButtons">
            <Button
              color="error"
              data-testid="dialog-cancel-button"
              onClick={() => {
                setIsDialogOpen(!isDialogOpen)
                useFormMethods.reset(defaultProjectData)
              }}
              autoFocus
              startIcon={<Close />}
            >
              {t("cancelBtn", { keyPrefix: "common" })}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              data-testid="dialog-submit-button"
              loading={isPostingNewProject}
              onClick={(e) => {
                handlePostProject(e)
              }}
              startIcon={<Check />}
            >
              {t("saveBtn", { keyPrefix: "common" })}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export default CreateProjectDialog

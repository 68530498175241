import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient, put as putClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

const defaultOnboardingDataAtom = atom<OnboardingRecord>((get) => {
  const { data: user } = get(userAtom)

  const defaultOnboardingData: OnboardingRecord = {
    onboardingId: user?.sub || "",
    userId: user?.sub || "",
    companyLanguage: "de",
    bankAccount: undefined,
    address: {
      city: "",
      country: "DE",
      houseNumber: "",
      addressAddition: "",
      state: "",
      street: "",
      zip: ""
    },
    ceo: {
      personId: undefined,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    companyName: "",
    companyNameAddition: "",
    companyRevenue: "",
    companySize: "",
    dataProtectionOfficer: {
      personId: undefined,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    isDataProtectionOfficerExternal: false,
    isSoleProprietorship: false,
    isExemptFromTaxFiling: true,
    ceoDeputies: [],
    development: undefined,
    finance: undefined,
    homePage: "",
    legalForm: "",
    phone: "",
    status: "NOT_YET_STARTED",
    support: undefined,
    techSupport: undefined,
    termsAndConditionsAccepted: false
  }

  return defaultOnboardingData
})

export const onboardingDataAtom = atomWithQuery<OnboardingRecord, ApiError>(
  (get) => {
    const { data: user } = get(userAtom)
    const defaultOnboardingData = get(defaultOnboardingDataAtom)

    return {
      queryKey: ["onboardingData"],
      queryFn: async () => {
        try {
          const response = await (
            await getClient({
              path: `/merchant-service/onboardings/${user?.sub}`
            })
          ).json()

          return response
        } catch (error) {
          return defaultOnboardingData
        }
      },

      retry: false,
      enabled: !!user?.sub,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
)

export const updateOnboardingDataAtom = atomWithMutation<
  unknown,
  OnboardingRecord,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async (formData) => {
      if (!user) {
        throw new Error("User not authenticated")
      }

      const response = await (
        await putClient({
          path: `/merchant-service/onboardings/${user.sub}`,
          body: formData
        })
      ).json()

      return response
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const languages: Language[] = [
  { lngCode: "de", flagCode: "de", nativeName: "Deutsch" },
  { lngCode: "en", flagCode: "gb", nativeName: "English" }
]

export const passwordStrengthTerms: Record<PasswordStrengthLevel, string> = {
  1: "veryStrong",
  2: "strong",
  3: "medium",
  4: "weak",
  5: "veryWeak"
}

export const passwordConditionStrings: (keyof PasswordConditions)[] = [
  "specialCharacter",
  "number",
  "minCharacters",
  "uppercase",
  "lowercase",
  "whitespace"
]

export const passwordHints: (keyof PasswordStrength)[] = [
  "hasAlphabeticalOrder",
  "hasKeyboardPattern",
  "hasSequentialNumbers"
]

import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Grid, Tab } from "@mui/material"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { BaseSyntheticEvent, SyntheticEvent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  AddressView,
  ContactView,
  GeneralInfoView,
  LanguageView
} from "src/features/account"
import { ApiError } from "src/shared/client"
import { merchantDataAtom, updateMerchantDataAtom } from "src/shared/stores"
import { filterFormData } from "src/widgets/account"

const CompanyDataBox = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage"
  })
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { handleSubmit, reset } = useFormContext<MerchantRecord>()

  const [{ mutateAsync: updateMerchantData }] = useAtom(updateMerchantDataAtom)
  const [{ data: merchantData, refetch: refetchMerchantData }] =
    useAtom(merchantDataAtom)

  useEffect(() => {
    if (location.pathname.endsWith("company") && !searchParams.get("tab")) {
      searchParams.set("tab", "1")

      navigate(`/account/company?${searchParams.toString()}`)
    }
  }, [navigate, searchParams])

  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    searchParams.set("tab", newTabPage)
    navigate(`/account/company?${searchParams.toString()}`)
    reset()
  }

  const handleUpdateMerchant = (e: BaseSyntheticEvent) =>
    handleSubmit(
      async (formData) => {
        try {
          await updateMerchantData({
            updatedMerchantData: filterFormData(formData)
          })
          refetchMerchantData()
          enqueueSnackbar({
            variant: "success",
            message: t("merchantUpdateSuccessSnackbar")
          })
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t("merchantUpdateFailureSnackbar"),
          details: Object.values(errors)
            .map((error) => error.message)
            .join(", "),
          autoHideDuration: null
        })
      }
    )(e)

  return (
    <Grid xs={12} item className="McpTabs" margin={2} alignItems="flex-start">
      <TabContext value={searchParams.get("tab") ?? "1"}>
        <Box className="McpTabsContext">
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            className="borderBottom"
          >
            <Tab label={t("company.commonTab.title")} value="1" />
            <Tab label={t("company.addressTab.title")} value="2" />
            <Tab label={t("company.contactTab.title")} value="3" />
            <Tab label={t("company.languageTab.title")} value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" className="McpSummaryView">
          <GeneralInfoView
            handleUpdateMerchant={handleUpdateMerchant}
            merchantData={merchantData}
          />
        </TabPanel>
        <TabPanel value="2" className="McpSummaryView">
          <AddressView handleUpdateMerchant={handleUpdateMerchant} />
        </TabPanel>
        <TabPanel value="3" className="McpSummaryView">
          <ContactView handleUpdateMerchant={handleUpdateMerchant} />
        </TabPanel>
        <TabPanel value="4" className="McpSummaryView">
          <LanguageView handleUpdateMerchant={handleUpdateMerchant} />
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default CompanyDataBox
